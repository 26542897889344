@charset 'UTF-8';
@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

@use 'sass/apply-directives';

$enable-dark-mode: false;

// Toastr
@use 'node_modules/ngx-toastr/toastr';

// Fixes

// Sentry overlay z-index
.sentry-error-embed-wrapper {
  z-index: 9999 !important;
}
