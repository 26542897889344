@layer base {
  /* Global resets */

  @font-face {
    font-family: 'Brandon Text';
    src: url('/assets/fonts/BrandonText-Regular.woff2') format('woff2'),
      url('/assets/fonts/BrandonText-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Brandon Text';
    src: url('/assets/fonts/BrandonText-Medium.woff2') format('woff2'),
      url('/assets/fonts/BrandonText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Brandon Text';
    src: url('/assets/fonts/BrandonText-Bold.woff2') format('woff2'),
      url('/assets/fonts/BrandonText-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  *,
  *::before,
  *::after {
    @apply box-border;
  }

  * {
    @apply m-0 p-0;
  }

  /* Html and body styles */
  html {
    @apply h-full;
  }

  body {
    @apply min-h-screen;
  }

  body {
    padding-top: max(56px, 28px + env(safe-area-inset-top, 0rem));
  }

  body.not-authenticated {
    @apply pt-0 text-sf-white bg-sf-body-not-authenticated bg-sf-logo-small bg-no-repeat bg-right-top lg:bg-sf-logo-large lg:bg-right-bottom;
  }

  h2 {
    @apply text-lg font-bold;
  }

  p {
    @apply mb-4;
  }

  a {
    @apply cursor-pointer;
  }

  ul {
    @apply list-disc;
  }

  ol,
  ul,
  dl {
    @apply pl-8 mb-4;
  }

  svg {
    @apply inline;
  }

  hr {
    @apply my-4 mx-0 border-0;
  }

  label {
    @apply inline-block text-base;
  }

  input {
    @apply focus:border-primary-sf-yellow outline-none border border-primary-sf-navy  dark:bg-primary-sf-navy-darker dark:border-sf-white-20-opacity text-sf-black dark:text-sf-white;
  }

  input[type='date'] {
    display: -webkit-flex;
    -webkit-flex: 1 0 0;
    &::-webkit-calendar-picker-indicator {
      @apply bg-sf-calendar dark:bg-sf-calendar-dark;
    }
  }

  select {
    @apply focus:border-primary-sf-yellow outline-none border border-primary-sf-navy  dark:bg-primary-sf-navy-darker dark:border-sf-white-20-opacity;
  }

  input[type='checkbox'] {
    @apply w-6 h-6 mt-0 border border-primary-sf-navy rounded-md appearance-none grid place-items-center bg-white dark:bg-primary-sf-navy-darker dark:border-sf-white-20-opacity focus:outline-none hover:cursor-pointer;
  }

  input[type='checkbox']:before {
    @apply content-[''] w-3.5 h-2.5 bg-no-repeat bg-center bg-contain bg-sf-check scale-0;
  }

  input[type='checkbox']:checked:before {
    @apply scale-100;
  }

  input[type='checkbox']:checked {
    @apply w-6 h-6 mt-0 border-primary-sf-yellow bg-primary-sf-yellow;
  }

  input[type='checkbox']:checked + label {
    @apply text-primary-sf-navy font-semibold;
  }

  input[type='checkbox']:checked + span {
    @apply text-primary-sf-navy font-semibold dark:text-sf-white;
  }
}

@layer components {
  .site-header {
    padding-top: max(12px, env(safe-area-inset-top, 0px));
  }

  .form-label {
    @apply mb-1.5 font-semibold;
  }

  .form-control {
    @apply block w-full p-3 text-base font-normal rounded-md transition ease-in-out placeholder:opacity-65;
  }

  .form-select {
    @apply block w-full p-3 text-base font-normal border text-sf-black dark:text-sf-white border-primary-sf-navy dark:border-sf-white-20-opacity bg-sf-arrow-down bg-16 bg-no-repeat bg-[right_0.75rem_center] appearance-none rounded-md transition ease-in-out outline-none dark:bg-sf-arrow-down-dark placeholder:opacity-65;
  }

  .form-check-input:disabled {
    @apply pointer-events-none filter-none opacity-50;
  }

  .form-check-input--large[type='checkbox'] {
    @apply border border-primary-sf-navy;
  }

  .input-group {
    @apply relative flex flex-wrap items-stretch w-full;
  }

  .input-group > .password-input {
    @apply relative flex flex-1 w-[1%] min-w-0;
  }

  .input-group:focus-within .password-toggle {
    @apply border-primary-sf-yellow;
  }

  .password-input {
    @apply border-r-0 rounded-tr-none rounded-br-none text-sf-black dark:text-sf-white;
  }

  .password-toggle {
    @apply flex items-center py-1.5 px-3 text-base text-center whitespace-nowrap border rounded-md text-sf-body-font dark:text-sf-white bg-sf-white dark:bg-primary-sf-navy-darker border-primary-sf-navy dark:border-sf-white-20-opacity border-l-0 rounded-tl-none rounded-bl-none;
  }

  #loginTitle h1,
  #loginTitle h2 {
    @apply text-center;
  }

  #loginContentErrorMessage {
    @apply hidden;
  }

  .card-full-value {
    @apply text-primary-sf-yellow text-3xl md:text-4xl xl:text-5xl font-bold mb-1;
  }

  .sf-card {
    @apply relative bg-white shadow-sm rounded-md max-xs:p-2 p-4 md:p-6 lg:p-8 mb-4 dark:bg-primary-sf-navy;

    &--ctf {
      @apply bg-sf-white-7-opacity;
    }

    &--read {
      @apply opacity-50;
    }
  }

  // Toast classes for ngx-toastr moved from _bootstrap-override.scss (Needs confirmation are these classes still in use)
  .toast-container {
    &.toast-top-full-width .ngx-toastr {
      @apply w-full;
    }

    .ngx-toastr {
      @apply rounded-none text-[0.8em];
    }

    .toast-title {
      @apply font-semibold;
    }

    .toast-message {
      @apply font-normal;
    }
  }

  .plan-overview {
    @apply bg-sf-logo-large bg-no-repeat bg-right-bottom bg-[100px_auto] max-xs:p-2 p-4 mb-4 rounded-lg bg-primary-sf-navy cursor-pointer text-white hover:bg-sf-bg-hover hover:bg-sf-logo-large-hover;

    .disabled {
      @apply grayscale opacity-60 select-none;
    }
  }
  .squared-bottom {
    @apply m-0 rounded-b-none;
  }

  .plan-overview__full {
    @apply bg-[140px_auto];
  }

  .btn-link {
    @apply bg-transparent border-none p-0 text-inherit underline cursor-pointer hover:no-underline;
  }

  .checkbox-row {
    @apply grid grid-cols-32 grid-rows-auto;
  }

  .login__logo {
    &--has-back-btn {
      button {
        @apply border-none flex justify-center items-center absolute h-10 px-4 py-2 rounded-full bg-sf-white-7-opacity xl:hidden;
      }

      img {
        @apply my-0 mx-auto xl:mx-0;
      }
    }
  }

  .pagination {
    @apply flex justify-center items-stretch rounded-md shadow-sm bg-sf-white mb-0 pl-0 dark:bg-primary-sf-navy;

    ul {
      @apply flex-1;
    }

    .page-item {
      @apply flex-1 text-center list-none flex;

      a {
        @apply flex-1 py-3 px-6 max-sm:p-2 self-center no-underline text-primary-sf-navy dark:text-sf-white font-bold;

        i {
          @apply text-base md:text-2xl font-medium;
        }
      }

      &:not(:last-of-type) {
        @apply border-r border-sf-light-blue dark:border-sf-white/10;
      }
    }

    .pagination-prev > .page-link,
    .pagination-next > .page-link {
      @apply no-underline font-normal;
    }

    .active {
      @apply bg-primary-sf-yellow text-primary-sf-navy;

      a {
        @apply dark:text-primary-sf-navy;
      }
    }
  }
  .steps-icon {
    circle {
      @apply fill-sf-background-light dark:fill-sf-white-10-opacity group-[.sf-card--ctf]:fill-sf-white-10-opacity;
    }

    path {
      @apply fill-sf-body-font opacity-50 dark:fill-sf-white group-[.sf-card--ctf]:fill-sf-white;
    }

    &--selected {
      circle {
        @apply fill-primary-sf-yellow group-[.sf-card--ctf]:fill-primary-sf-yellow;
      }
      path {
        @apply opacity-100 fill-primary-sf-navy group-[.sf-card--ctf]:fill-primary-sf-navy;
      }
    }
    &--completed {
      circle {
        @apply fill-tertiary-sf-pale-green group-[.sf-card--ctf]:fill-tertiary-sf-pale-green;
      }
      path {
        @apply stroke-sf-white fill-none group-[.sf-card--ctf]:stroke-sf-white;
      }
    }
  }

  // Positioning tweaks
  .steps__list-item--4 svg path {
    transform: translate(-1px, 0);
  }

  .btn {
    &.primary {
      @apply bg-primary-sf-yellow;
    }

    &.secondary {
      @apply bg-primary-sf-navy text-sf-white dark:bg-secondary-sf-blue-light dark:text-primary-sf-navy;
    }

    &.tertiary {
      @apply bg-sf-grey-vlight;
    }

    &.success {
      @apply bg-tertiary-sf-pale-green text-sf-white;
    }

    &.warning {
      @apply bg-sf-error-border text-sf-white;
    }

    &.white {
      @apply bg-sf-white text-primary-sf-navy shadow-sm;
    }

    &.dark {
      @apply dark:bg-secondary-sf-blue-light dark:text-primary-sf-navy;
    }

    &.disabled {
      @apply bg-sf-background-light dark:bg-sf-white-10-opacity  text-sf-body-font  dark:text-sf-white cursor-not-allowed hover:cursor-not-allowed hover:opacity-50 active:scale-100;
    }

    &.btn-small {
      @apply py-2 px-4 text-sm flex-grow-0 flex max-xl:items-center xl:text-base xl:py-4 xl:px-8 dark:bg-sf-white-10-opacity dark:text-sf-white;

      &--xs {
        @apply py-[11.5px];
      }

      i {
        @apply text-lg mr-1.5 xl:text-2xl xl:leading-none  dark:text-primary-sf-yellow;
      }
    }
  }

  .btn-hover:focus .btn-icon > i,
  .btn-hover:hover .btn-icon > i {
    @apply text-primary-sf-navy;
  }

  .sf-alert {
    &.warning {
      @apply bg-sf-error-tint border-sf-error-border dark:bg-sf-error-tint-dark;

      i {
        @apply text-sf-error-border;
      }
    }
    &.info {
      @apply bg-sf-yellow-light border-primary-sf-yellow dark:bg-sf-info-a-tint-dark;

      i {
        @apply text-sf-black dark:text-sf-white;
      }
    }
    &.success {
      @apply bg-sf-success-tint dark:bg-sf-success-tint-dark border-sf-success-border;

      i {
        @apply text-sf-success-border;
      }
    }
    &.white-alert {
      @apply bg-sf-white dark:bg-primary-sf-navy border-none;

      i {
        @apply text-sf-black;
      }
    }
  }

  .transaction-controls-button,
  .fund-controls-button {
    &--active {
      @apply bg-primary-sf-yellow text-primary-sf-navy font-bold py-1.5 rounded-md border-primary-sf-yellow [&:not(:last-of-type)]:border-r [&:not(:last-of-type)]:border-primary-sf-yellow;
    }

    .active {
      @apply text-primary-sf-navy font-bold;
    }
  }

  .carousel {
    @apply bg-sf-light-grey flex flex-col-reverse justify-center items-center p-4 md:p-6 xl:p-8;
  }

  .carousel-indicators {
    @apply flex  gap-3 pt-2;

    button {
      @apply bg-sf-grey-vlight w-3.5 h-3.5 rounded-full;

      &.active {
        @apply bg-primary-sf-yellow;
      }
    }
  }

  #ios-install-instructions {
    @apply hidden;
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices - https://stackoverflow.com/questions/30102792/css-media-query-to-target-only-ios-devices*/
    @media (display-mode: browser) {
      /* CSS only if PWA not installed */
      #ios-install-instructions {
        @apply block;
      }
    }
  }

  #default-install-instructions {
    @apply hidden;
  }

  @supports not (-webkit-touch-callout: none) {
    /* CSS specific non-iOS devices - https://stackoverflow.com/questions/30102792/css-media-query-to-target-only-ios-devices*/
    @media (display-mode: browser) {
      /* CSS only if PWA not installed */
      #default-install-instructions {
        @apply inline-flex;
      }
    }
  }
}

@layer utilities {
  .overflow-unset {
    overflow: unset;
  }

  .yellow-divider {
    @apply border-b border-primary-sf-yellow;
  }

  .active {
    @apply text-primary-sf-yellow font-bold;
  }

  .active svg {
    @apply stroke-primary-sf-yellow;
  }

  .hidden-step {
    @apply pointer-events-none invisible h-0 hidden;
  }

  .help-savings {
    .visible {
      @apply mt-4 pt-4 border-t border-sf-background-light text-sm md:text-base transition-max-height duration-300 ease-in-out;

      &-icon > .show-icon {
        @apply text-sf-body-font font-bold relative w-8 h-[33px] bg-sf-minus bg-no-repeat;
      }
    }
  }

  .security-class {
    .circle {
      path {
        @apply fill-sf-error-border;
      }
    }
    .tag {
      @apply bg-sf-error-border/10 dark:bg-sf-white-10-opacity;
    }
  }
}

.asset-allocation-bars-container {
  @apply relative w-full h-3;
}

.asset-allocation-bars-bg {
  @apply w-full bg-gray-100 rounded-full h-3 absolute dark:bg-opacity-10;
}

.asset-allocation-bars-colour {
  @apply rounded-full h-3 absolute;
}

.date-toggle-button {
  @apply text-white text-sm font-bold flex-1 rounded-md p-1 md:py-2 hover:bg-white/5;
}
